import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import "./ApplyForm.css";
import { Helmet, HelmetProvider } from "react-helmet-async";

const ApplyForm = () => {
    const location = useLocation();
    const { jobTitle } = location.state || {};
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        resume: null,
    });
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleFileChange = (e) => {
        setFormData((prev) => ({ ...prev, resume: e.target.files[0] }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const form = new FormData();
        form.append("name", formData.name);
        form.append("email", formData.email);
        form.append("resume", formData.resume);
        form.append("jobTitle", jobTitle);

        try {
            const response = await fetch("jobForms.php", {
                method: "POST",
                body: form,
            });

            const result = await response.json();
            if (result.success) {
                setFormSubmitted(true);
                setSuccessMessage("Your application has been submitted successfully!");
            } else {
                alert(result.message || "Failed to submit application.");
            }
        } catch (error) {
            console.error("Error submitting form:", error);
            alert("An error occurred. Please try again.");
        }
    };

    return (
        <div>
            <HelmetProvider>
                <Helmet>
                    <title>Apply for {jobTitle} - thirtyzero.com</title>
                </Helmet>

                <div className="form-container">
                    <h1>Apply for {jobTitle}</h1>
                    {formSubmitted ? (
                        // Show success message with SVG and animation
                        <div className="success-container">
                            <div className="success-svg">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 64 64"
                                    width="150"
                                    height="150"
                                    fill="none"
                                >
                                    <circle cx="32" cy="32" r="30" fill="#D1FAE5" />
                                    <path
                                        d="M26 34.5l6 6L44 28"
                                        stroke="#10B981"
                                        strokeWidth="4"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </div>
                            <div className="success-message">
                                <p>{successMessage}</p>
                            </div>
                        </div>
                    ) : (
                        // Display the form if not submitted
                        <form onSubmit={handleSubmit}>
                            <label>
                                Name:
                                <input type="text" name="name" required onChange={handleChange} />
                            </label>
                            <label>
                                Email:
                                <input type="email" name="email" required onChange={handleChange} />
                            </label>
                            <label>
                                Resume:
                                <input
                                    type="file"
                                    name="resume"
                                    accept=".pdf,.doc,.docx"
                                    required
                                    onChange={handleFileChange}
                                />
                            </label>
                            <button type="submit">Submit</button>
                        </form>
                    )}
                </div>
            </HelmetProvider>
        </div>
    );
};

export default ApplyForm;
