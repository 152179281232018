import React, { Suspense, lazy } from 'react';
import '../src/App.css';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import TopNavbar from './Component/TopNavbar';
import Notfound from './Component/Notfound';
import { ReactComponent as Spinnersvg } from '../src/Images/spinner.svg';
import Jobs from './pages/Jobs';
import ApplyForm from './Component/ApplyForm';
import AdminPanel from './pages/AdminPanel';
import JobDetails from './Component/JobDetails';

// Lazy load pages with prefetching
const Home = lazy(() => import('../src/pages/Home'));
const AboutUs = lazy(() => import('./pages/AboutUs'));
const TermsAndConditions = lazy(() => import('./Component/TermConditionPage'));
const WebDevelopment = lazy(() => import('./pages/Services/WebDevelopment'));
const MobileDevelopment = lazy(() => import('./pages/Services/MobileDevelopment'));
const Devops = lazy(() => import('./pages/Services/DevOps'));
const UIDesign = lazy(() => import('./pages/Services/UIDesign'));
const QADevelopment = lazy(() => import('./pages/Services/QADevelopment'));
const InfraDevelopment = lazy(() => import('./pages/Services/InfraDevelopment'));
const Career = lazy(() => import('./Component/Career'));
const Quoit = lazy(() => import('./Component/Quoit'));
const PrivacyPolicy = lazy(() => import('./Component/PrivacyPolicy'));

const StructuredData = ({ data }) => {
  if (!data) return null;
  return <script type="application/ld+json">{JSON.stringify(data)}</script>;
};
const AppContent = () => {
  const location = useLocation();

  // Function to generate structured data for each route
  const getStructuredData = (path) => {
    switch (path) {
      case "/":
        return {
          "@context": "https://schema.org",
          "@type": "WebPage",
          "name": "Home",
          "description": "Welcome to our homepage where you can learn about our services and solutions.",
          "url": "https://thirtyzero.com"
        };
      case "/about":
        return {
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "thirtyzero.com",
          "url": "https://thirtyzero.com/about",
        };
      case "/terms":
        return {
          "@context": "https://schema.org",
          "@type": "WebPage",
          "name": "Terms and Conditions",
          "description": "Read our terms and conditions to understand your rights and obligations while using our services.",
          "url": "https://thirtyzero.com/terms"
        };
      case "/web-development":
        return {
          "@context": "https://schema.org",
          "@type": "Service",
          "name": "Web Development",
          "description": "We provide top-notch web development services to build responsive and secure websites.",
          "provider": {
            "@type": "Organization",
            "name": "thirtyzero.com",
            "url": "https://thirtyzero.com/web-development"
          }
        };
      case "/mobile-development":
        return {
          "@context": "https://schema.org",
          "@type": "Service",
          "name": "Mobile Development",
          "description": "We specialize in mobile app development to create feature-rich and user-friendly applications.",
          "provider": {
            "@type": "Organization",
            "name": "thirtyzero.com",
            "url": "https://thirtyzero.com/mobile-development"
          }
        };
      case "/devops":
        return {
          "@context": "https://schema.org",
          "@type": "Service",
          "name": "DevOps",
          "description": "Our DevOps services enhance collaboration, automation, and monitoring for seamless operations.",
          "provider": {
            "@type": "Organization",
            "name": "thirtyzero.com",
            "url": "https://thirtyzero.com/devops"
          }
        };
      case "/uiux-design":
        return {
          "@context": "https://schema.org",
          "@type": "Service",
          "name": "UI/UX Design",
          "description": "We offer exceptional UI/UX design services to craft visually appealing and user-friendly interfaces.",
          "provider": {
            "@type": "Organization",
            "name": "thirtyzero.com",
            "url": "https://thirtyzero.com/uiux-design"
          }
        };
      case "/qa-testing":
        return {
          "@context": "https://schema.org",
          "@type": "Service",
          "name": "QA Testing",
          "description": "Our QA testing services ensure the highest quality and reliability of your applications.",
          "provider": {
            "@type": "Organization",
            "name": "thirtyzero.com",
            "url": "https://thirtyzero.com/qa-testing"
          }
        };
      case "/infrastructure":
        return {
          "@context": "https://schema.org",
          "@type": "Service",
          "name": "Infrastructure Development",
          "description": "We provide infrastructure development services to set up secure, scalable, and robust systems.",
          "provider": {
            "@type": "Organization",
            "name": "thirtyzero.com",
            "url": "https://thirtyzero.com/infrastructure"
          }
        };
        case "/career":
          return {
            "@context": "https://schema.org",
            "@type": "JobPosting",
            "title": "Career Opportunities",
            "description": "Explore career opportunities and join our innovative and dynamic team.",
            "hiringOrganization": {
              "@type": "Organization",
              "name": "ThirtyZero",
              "url": "https://thirtyzero.com"
            },
            "jobLocation": {
              "@type": "Place",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "123 Tech Street",
                "addressLocality": "Lahore",
                "addressRegion": "Punjab",
                "postalCode": "54000",
                "addressCountry": "PK"
              }
            },
            "employmentType": "Full-time",
            "validThrough": "2025-01-15",
            "baseSalary": {
              "@type": "MonetaryAmount",
              "currency": "USD",
              "value": {
                "@type": "QuantitativeValue",
                "value": "50000",
                "unitText": "YEAR"
              }
            }
          };
      case "/quote":
        return {
          "@context": "https://schema.org",
          "@type": "WebPage",
          "name": "Get a Quote",
          "description": "Request a quote for our services and let us know how we can assist you.",
          "url": "https://thirtyzero.com/quote"
        };
      case "/privacyPolicy":
        return {
          "@context": "https://schema.org",
          "@type": "WebPage",
          "name": "Privacy Policy",
          "description": "Read our privacy policy to understand how we handle your personal information.",
          "url": "https://thirtyzero.com/privacyPolicy"
        };
      default:
        return null;
    }
  };

  const structuredData = getStructuredData(location.pathname);

  return (
    <>
      <TopNavbar />
      <StructuredData data={structuredData} />
      <Suspense fallback={
        <div className='spinnerMain' aria-label="Loading content">
          <div className='spinner'></div>
          <Spinnersvg />
        </div>
      }>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/terms" element={<TermsAndConditions />} />
          <Route path="/web-development" element={<WebDevelopment />} />
          <Route path="/mobile-development" element={<MobileDevelopment />} />
          <Route path="/devops" element={<Devops />} />
          <Route path="/qa-testing" element={<QADevelopment />} />
          <Route path="/uiux-design" element={<UIDesign />} />
          <Route path="/infrastructure" element={<InfraDevelopment />} />
          <Route path="/career" element={<Career />} />
          <Route path="/quote" element={<Quoit />} />
          <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/jobs" element={<Jobs />} />
          <Route path="/apply" element={<ApplyForm />} />
          <Route path="/admin" element={<AdminPanel />} />
          <Route path="/job-details" element={<JobDetails />} />
          <Route path="*" element={<Notfound />} />
        </Routes>
      </Suspense>
    </>
  );
};

const App = () => {
  return (
    <Router basename="/">
      <AppContent />
    </Router>
  );
};

export default App;
