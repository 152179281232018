import React, { useState } from "react";
import { Container, Form, Alert } from "react-bootstrap";
import { Helmet, HelmetProvider } from "react-helmet-async";
import './AdminPanel.css';

const AdminPanel = () => {
    const [jobTitle, setJobTitle] = useState("");
    const [jobDescription, setJobDescription] = useState("");
    const [message, setMessage] = useState(null);
    const [messageType, setMessageType] = useState(""); 
    const [jobLink, setJobLink] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!jobTitle || !jobDescription) {
            setMessageType("error");
            setMessage("Both job title and description are required!");
            return;
        }

        try {
            const response = await fetch("jobs.php", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    title: jobTitle,
                    description: jobDescription,
                }),
            });

            const result = await response.json();

            if (result.success) {
                setMessageType("success");
                setMessage("Job posted successfully!");
                setJobLink("/jobs");
                setJobTitle("");
                setJobDescription("");
            } else {
                setMessageType("error");
                setMessage(result.message || "Failed to post the job.");
                setJobLink(null);
            }
        } catch (error) {
            setMessageType("error");
            setMessage("An error occurred while posting the job.");
            setJobLink(null);
            console.error("Error posting job:", error);
        }
    };

    return (
        <div className="admin-panel">
            <HelmetProvider>
                <Helmet>
                    <title>Admin Panel - Post Job</title>
                </Helmet>
                <Container className="admin-container">
                    <h1 className="text-center my-4 admin-title">Admin Panel - Post a Job</h1>
                    {message && (
                        <Alert 
                            variant={messageType === "success" ? "success" : "danger"} 
                            dismissible 
                            onClose={() => setMessage(null)}
                            className="admin-alert"
                        >
                            {message}
                            {messageType === "success" && jobLink && (
                                <div>
                                    <a href={jobLink} target="_blank" rel="noopener noreferrer">
                                        Click here to see the job
                                    </a>
                                </div>
                            )}
                        </Alert>
                    )}
                    <Form onSubmit={handleSubmit} className="admin-form">
                        <Form.Group controlId="jobTitle" className="mb-3">
                            <Form.Label>Job Title</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter job title"
                                value={jobTitle}
                                onChange={(e) => setJobTitle(e.target.value)}
                                required
                                className="admin-input"
                            />
                        </Form.Group>

                        <Form.Group controlId="jobDescription" className="mb-3">
                            <Form.Label>Job Description</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={4}
                                placeholder="Enter job description"
                                value={jobDescription}
                                onChange={(e) => setJobDescription(e.target.value)}
                                required
                                className="admin-textarea"
                            />
                        </Form.Group>

                        <button type="submit" className="admin-button">
                            Post Job
                        </button>
                    </Form>
                </Container>
            </HelmetProvider>
        </div>
    );
};

export default AdminPanel;
