import React, { useEffect, useState } from "react";
import { Card, Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "../pages/Jobs.css";
import { ReactComponent as Nojob } from '../Images/nojob.svg'
import ContactFooter from "../Component/ContactFooter";
import ContactPage from "../Component/ContactPage";
import { Helmet, HelmetProvider } from "react-helmet-async";


const Jobs = () => {
    const [jobsData, setJobsData] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        // Log visitor details
        fetch("log_visit.php", { method: "POST" })
            .then((response) => response.json())
            .catch((error) => console.error("Error logging visit:", error));

        // Fetch job data
        fetch("jobs.php")
            .then((response) => response.json())
            .then((data) => setJobsData(data))
            .catch((error) => console.error("Error fetching jobs:", error));
    }, []);

    const handleView = (job) => {
        navigate("/job-details", { state: { job } });
    };

    const handleApply = (job) => {
        navigate("/apply", { state: { jobTitle: job.title } });
    };

    return (
        <div className="main-jobContainer">
            <HelmetProvider>
                <Helmet>
                    <title>Jobs - thirtyzero.com</title>
                </Helmet>

                <Container>
                    <h1 className="text-center mb-4">Available Jobs</h1>
                    {jobsData.length === 0 ? (
                        <p className="text-center text-muted"><Nojob/></p>
                          
                    ) : (
                        <Row>
                            {jobsData.map((job) => (
                                <Col key={job.id} md={4} className="mb-4">
                                    <Card className="shadow-lg jobs-cards">
                                        <Card.Body >
                                            <Card.Title>{job.title}</Card.Title>
                                            <Card.Text>{job.description.substring(0, 30)}...</Card.Text>
                                            <div className="jobs_buttons">
                                            <button
                                                className="button"
                                                onClick={() => handleApply(job)}
                                            >
                                                Apply Now
                                            </button>
                                            <button
                                                className="button1"
                                                onClick={() => handleView(job)}
                                            >
                                                view
                                            </button>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    )}
                </Container>
                <ContactPage />
                <ContactFooter />
            </HelmetProvider>
        </div>
    );
};

export default Jobs;
