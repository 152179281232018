import React, { useState, useEffect } from 'react'
import '../Component/ContactFooter.css'
import { Col, Container, Row } from 'react-bootstrap'
import { Email } from '@mui/icons-material'
import { ReactComponent as Nakwisvg } from '../Images/Primary Logo 2.svg';
import { Link, useLocation } from 'react-router-dom'


function ContactFooter() {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const location = useLocation();

    // Scroll to the top when the route changes
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    const handleSubmit = (e) => {
        e.preventDefault();

        fetch('submit_newsletter.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({
                email: email,
            }),
        })
            .then((response) => response.text())
            .then((data) => {
                setMessage(data); 
                setSuccess(true);
                setEmail(''); 
            })
            .catch((error) => {
                setMessage('An error occurred. Please try again.');
                setSuccess(false); 
            });
    };

    return (
        <div className='Contact-footer-main'>
            <div className="contact-footer-container">
                <Container>
                    <Row>
                        <Col lg='6'>
                            <div className="contact-page-right">
                                <div className="contact-page-right-info">
                                    <h1>Contact Us</h1>
                                    <hr />
                                    <h3><Email />hi@thirtyzero.com</h3>
                                </div>
                                <div className="contact-page-newsletter">
                                    <h1>Sign Up for our Newsletter</h1>
                                    <form onSubmit={handleSubmit}>
                                        <input
                                            type="email"
                                            name="email"
                                            placeholder="Enter your email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            required
                                        />
                                        <button type="submit">Submit</button>
                                    </form>
                                    {message && (
                                        <div
                                            style={{
                                                color: success ? 'green' : 'red',
                                                display: 'block',
                                            }}
                                        >
                                            {message}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Col>
                        <Col lg='6'>
                            <Container>
                                <Row>
                                    <Col lg='6' xs='6'>
                                        <div className="contact-page-links">
                                            <h1>Quick Links</h1>
                                            <hr />
                                            <ul>
                                                <Link to='/' style={{ textDecoration: 'none' }}><li>Home</li></Link>
                                                <Link to='/about' style={{ textDecoration: 'none' }}><li>About Us</li></Link>
                                                <Link to='https://thirtyzero.com/blog/' style={{ textDecoration: 'none' }}><li>Blogs</li></Link>
                                                <Link to='/quote' style={{ textDecoration: 'none' }}><li>Contact Us</li></Link>
                                                <Link to='/career' style={{ textDecoration: 'none' }}><li>Careers</li></Link>
                                            </ul>
                                        </div>
                                    </Col>
                                    <Col lg='6' xs='6'>
                                        <div className="contact-page-services">
                                            <h1>Services</h1>
                                            <hr />
                                            <ul>
                                                <Link to='/web-development' style={{ textDecoration: 'none' }}><li>Web Development</li></Link>
                                                <Link to='/mobile-development' style={{ textDecoration: 'none' }}><li>Mobile Development</li></Link>
                                                <Link to='/infrastructure' style={{ textDecoration: 'none' }}><li>Infrastructure</li></Link>
                                                <Link to='/devops' style={{ textDecoration: 'none' }}><li>DevOps</li></Link>
                                                <Link to='/uiux-design' style={{ textDecoration: 'none' }}><li>UI/UX Design</li></Link>
                                                <Link to='/qa-testing' style={{ textDecoration: 'none' }}><li>QA Testing</li></Link>
                                            </ul>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                            <div className="contact-page-footer-logo">
                                <Nakwisvg />
                                <p>The goal is to deliver quality-focused software products to aspiring businesses.</p>
                                <hr />
                                <div className="copyright">
                                    <div className='copyrightText'>
                                        <span>Copyright@ thirtyzero 2024</span>
                                    </div>

                                    <div className="conditions">
                                        <Link to="/terms" style={{ textDecoration: 'none' }}>
                                            <span>Terms & Conditions</span>
                                        </Link>
                                        <Link to='/privacyPolicy' style={{ textDecoration: 'none' }}>
                                            <span id='privacy'>Privacy Policy</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default ContactFooter;
