import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import ContactFooter from "../Component/ContactFooter";
import '../Component/JobDetails.css';

const JobDetails = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { job } = location.state || {};

    if (!job) {
        return <p className="text-center">No job details available.</p>;
    }

    const handleApply = () => {
        navigate("/apply", { state: { jobTitle: job.title } });
    };

    return (
        <div>
            <HelmetProvider>
                <Helmet>
                    <title>{job.title} - thirtyzero.com</title>
                </Helmet>

                <div className="job-container">
                    <div className="job-image">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 64 64"
                            width="150"
                            height="150"
                            fill="none"
                        >
                            <circle cx="32" cy="32" r="30" fill="#E5E7EB" />
                            <path
                                d="M32 22a10 10 0 1 1-10 10 10 10 0 0 1 10-10z"
                                fill="#60A5FA"
                            />
                            <path
                                d="M21 41h22a4 4 0 0 1 4 4v2H17v-2a4 4 0 0 1 4-4z"
                                fill="#93C5FD"
                            />
                            <path
                                d="M41 32h6v2h-6zM17 32h6v2h-6zM39 23h2v6h-2zM23 23h2v6h-2z"
                                fill="#2563EB"
                            />
                        </svg>
                    </div>
                    <h1>{job.title}</h1>
                    <p>{job.description}</p>

                    <h3>Required Qualifications:</h3>
                    <ul>
                        <li>Degree in a relevant field (Computer Science, Engineering, etc.).</li>
                        <li>Proven experience in similar roles or technologies specific to {job.title}.</li>
                        <li>Strong problem-solving and communication skills.</li>
                        <li>Ability to work independently and as part of a team.</li>
                    </ul>

                    <h3>Why Join Us?</h3>
                    <ul>
                        <li>Competitive salary and benefits package.</li>
                        <li>Opportunity for career growth and development.</li>
                        <li>Work with a dynamic, creative, and supportive team.</li>
                        <li>Flexible work hours and a positive work-life balance.</li>
                    </ul>

                    <button className="button" onClick={handleApply}>
                        Apply Now
                    </button>
                </div>
                <ContactFooter />
            </HelmetProvider>
        </div>
    );
};

export default JobDetails;
